.ethText {
  font-size: 0.5rem;
}

.nextEntryButton {
  cursor: default !important;
}

.nextEntryButton:hover {
  cursor: default !important;
}

.dutchAuctionQuantityContainer {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.mintButton {
  cursor: pointer;
  position: relative;
  padding: 0.5rem 1.5rem;
  border-radius: 3.75rem;
  /* line-height: 1rem; */
  letter-spacing: 1px;
  font-weight: 600;
  color: #0dbd00;
  border: none;
  text-align: center;
  background-image: linear-gradient(-180deg, #f2ff00 0%, #fffb00 85%);
  box-shadow: 0 0.25rem 1rem 0 rgba(160, 160, 160, 0.5), 0 0 0.25rem rgb(209, 212, 0) inset, 0 0.75rem 1.25rem rgba(255, 255, 255, 0.4) inset,
    0 -0.5rem 0.25rem 0 rgb(236, 220, 0) inset;
  font-family: 'Fredoka One';
  transition: all 0.2s ease-in-out;
}

.mintButton::before {
  content: '';
  display: block;
  height: 0.125rem;
  position: absolute;
  top: 0.25rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 3rem);
  background: #fff;
  border-radius: 100%;
  opacity: 0.7;
  background-image: linear-gradient(-270deg, rgba(255, 255, 255, 0) 0%, #ffffff 20%, #ffffff 80%, rgba(255, 255, 255, 0) 100%);
}

.mintButton:hover {
  transform: scale(1.1);
}

.mintButton:disabled {
  cursor: default;
  transform: scale(1);
  color: #0dbd007a;
  background-image: linear-gradient(-180deg, #f9ff89 0%, #f9ff89 85%);
  box-shadow: 0 0.25rem 1rem 0 rgba(160, 160, 160, 0.5), 0 0 0.25rem rgb(207, 209, 88) inset, 0 0.75rem 1.25rem rgba(255, 255, 255, 0.4) inset,
    0 -0.5rem 0.25rem 0 rgb(231, 223, 106) inset;
}

.mintWidgetContainer {
  background-image: linear-gradient(-180deg, #f2ff00 0%, #fffb00 85%);
  box-shadow: 0 0.25rem 1rem 0 rgba(160, 160, 160, 0.5), 0 0 0.25rem rgb(209, 212, 0) inset, 0 0.75rem 1.25rem rgba(255, 255, 255, 0.4) inset,
    0 -0.5rem 0.25rem 0 rgb(236, 220, 0) inset;
  max-width: 12rem;
}

.tweetButton {
  background-color: #00acee;
}

.tweetButton:hover {
  background-position: 0 -21px;
}
