@font-face {
  font-family: 'Frankfurter Std';
  src: local('Frankfurter Std'), url(../../assets/fonts/FrankfurterStd.otf) format('truetype');
}

@font-face {
  font-family: 'Myriad Pro';
  src: local('Myriad Pro'), url(../../assets/fonts/MyriadPro-Regular.otf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Vag Rounded';
  src: local('Vag Rounded'), url(../../assets/fonts/VagRoundedLight.otf) format('truetype');
}

.h-font {
  font-family: 'Frankfurter Std';
}

.cloudboy-position {
  top: 5%;
  right: 11%;
}

.cloudboy-sit-position {
  top: 40%;
  left: -8%;
  z-index: -10;
}

.walk-position {
  top: 60%;
  right: 23%;
  width: 44%;
}

.stand-position {
  top: 58%;
  right: 20%;
  width:  18%;
}

.tiny-font {
  font-size: 0.7rem;

}

.p-font {
  font-family: 'Vag Rounded';
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.horizontal-scene-container {
  width: 100%;
  height: 100%;
  cursor: grab;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.fwy-background {
  background-image: radial-gradient(circle at 50% 30%, rgb(78, 255, 215), rgb(2,242,255) 35%);
}
