@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Frankfurter Std';
  src: local('Frankfurter Std'), url(/src/assets/fonts/FrankfurterStd.otf) format('truetype');
}

@font-face {
  font-family: 'Vag Rounded';
  src: local('Vag Rounded'), url(/src/assets/fonts/VagRoundedLight.otf) format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

html, body {
    height:100%;
    overflow-x: hidden;
} 

body {
    /*background-color: white;*/
    background-image: url('/src/assets/images/dev/bg-attempt.png');
    background-size: cover;
    background-repeat: no-repeat;

     /*Fix gradient */
/*    background-image: linear-gradient(rgb(0, 136, 255) 70%, rgb(0, 197, 208) 85%, rgb(42, 239, 255) 95%);
    background-size: 100% 100%;
    background-attachment: fixed;*/
}

.friendsie-pill {
  cursor: pointer;
  position: relative;
  padding: 0.25rem 1rem;
  border-radius: 3.75rem;
  line-height: 1rem;
  font-size: 0.65rem;
  font-weight: 600;
  color: #0dbd00;
  border: none;
  text-align: center;
  background-image: linear-gradient(-180deg, #f2ff00 0%, #fffb00 85%);
  box-shadow: 0 0.25rem 1rem 0 rgba(160, 160, 160, 0.5), 0 0 0.25rem rgb(209, 212, 0) inset, 0 0.75rem 1.25rem rgba(255, 255, 255, 0.4) inset,
    0 -0.5rem 0.25rem 0 rgb(236, 220, 0) inset;
  font-family: 'Fredoka One', cursive;
}

.friendsie-pill::before {
  content: '';
  display: block;
  height: 0.125rem;
  position: absolute;
  top: 0.25rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 3rem);
  background: #fff;
  border-radius: 100%;
  opacity: 0.7;
  background-image: linear-gradient(-270deg, rgba(255, 255, 255, 0) 0%, #ffffff 20%, #ffffff 80%, rgba(255, 255, 255, 0) 100%);
}

.friendsie-pill-disabled {
  cursor: default;
  position: relative;
  padding: 0.25rem 1rem;
  border-radius: 3.75rem;
  line-height: 1rem;
  font-size: 0.65rem;
  font-weight: 600;
  border: none;
  text-align: center;
  opacity: 0.1;
  background-image: linear-gradient(-180deg, #f2ff00 0%, #fffb00 85%);
  box-shadow: 0 0.25rem 1rem 0 rgba(160, 160, 160, 0.5), 0 0 0.25rem rgb(209, 212, 0) inset, 0 0.75rem 1.25rem rgba(255, 255, 255, 0.4) inset,
    0 -0.5rem 0.25rem 0 rgb(236, 220, 0) inset;
  font-family: 'Fredoka One', cursive;
}

.glowing-outline {
  background-color: transparent;
  border-radius: 50px;
  border: 1px;
  box-shadow: 0 0 10px #ffe600, inset 0 0 10px #ffe600;
  animation: categorypulse 2s linear 1s infinite;
  -webkit-animation: categorypulse 2s linear 1s infinite;
}

.glowing-filled {
  /* background-color: #91f3f6c7; */
  border-radius: 50px;
  border: 1px;
  height: 60px;
  width: 60px;
  margin-top: -5px;
  box-shadow: 0 0 8px #fff, inset 0 0 8px #fff;
  animation: outlinepulse 1.5s linear 1s infinite;
  -webkit-animation: outlinepulse 1.5s linear 1s infinite;
}

@-webkit-keyframes outlinepulse {
  0% {
    box-shadow: 0 0 10px #fff, inset 0 0 10px #fff;
  }
  50% {
    box-shadow: 0 0 30px #fff, inset 0 0 30px #fff;
  }
  100% {
    box-shadow: 0 0 10px #fff, inset 0 0 10px #fff;
  }
}

@keyframes outlinepulse {
  0% {
    box-shadow: 0 0 10px #fff, inset 0 0 10px #fff;
  }
  50% {
    box-shadow: 0 0 30px #fff, inset 0 0 30px #fff;
  }
  100% {
    box-shadow: 0 0 10px #fff, inset 0 0 10px #fff;
  }
}

@-webkit-keyframes categorypulse {
  0% {
    box-shadow: 0 0 10px #ffe600, inset 0 0 10px #ffe600;
  }
  50% {
    box-shadow: 0 0 30px #ffe600, inset 0 0 30px #ffe600;
  }
  100% {
    box-shadow: 0 0 10px #ffe600, inset 0 0 10px #ffe600;
  }
}

@keyframes categorypulse {
  0% {
    box-shadow: 0 0 10px #ffe600, inset 0 0 10px #ffe600;
  }
  50% {
    box-shadow: 0 0 30px #ffe600, inset 0 0 30px #ffe600;
  }
  100% {
    box-shadow: 0 0 10px #ffe600, inset 0 0 10px #ffe600;
  }
}

.grid-lines {
  border-left: 3px solid black;
  border-image: linear-gradient(to top, rgba(0, 0, 0, 0) 5%, rgb(40, 217, 209) 50%, rgba(0, 0, 0, 0) 95%);
  border-image-slice: 1;
}

.grid-divider {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  /* border: 1px solid rgb(253 224 71); */
  border: 1px solid rgb(40, 217, 209, 0.25);
}

.o-fade-out {
  transition: all 2s;
  -webkit-transition: all 2s;
  opacity: 0;
}

.o-fade-in {
  transition: all 2s;
  -webkit-transition: all 2s;
  opacity: 1;
}
