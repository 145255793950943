.scaleOnHover {
  transition: all 0.2s ease-in-out;
}

.scaleOnHover:hover {
  transform: scale(1.2) translate(0, -5px);
}

.grayScale {
  -webkit-filter: brightness(10%) grayscale(100%);
  -moz-filter: brightness(10%) grayscale(100%);
  -o-filter: brightness(10%) grayscale(100%);
  -ms-filter: brightness(10%) grayscale(100%);
  filter: brightness(10%) grayscale(100%);
  opacity: 0.1;
}
