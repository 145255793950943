.categoryIcon {
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -1;
}

.arrowIcon {
  transition: all 0.2s ease-in-out;
}

.arrowIcon:hover {
  transform: scale(1.2) translate(0, -5px);
  border-radius: 50px;
  border: 3px;
  box-shadow: 0 0 16px #fff, inset 0 0 16px #fff;
}

.scaleOnHover {
  transition: all 0.2s ease-in-out;
}

.scaleOnHover:hover {
  transform: scale(1.2);
}

.mintButton {
  text-align: center;
  letter-spacing: 2px;
  cursor: pointer;
  position: relative;
  padding: 0.75rem 1.5rem;
  border-radius: 1rem;
  line-height: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: #0dbd00;
  border: none;
  background-image: linear-gradient(-180deg, #f2ff00 0%, #fffb00 85%);
  box-shadow: 0 0.25rem 1rem 0 rgba(160, 160, 160, 0.5), 0 0 0.25rem rgb(209, 212, 0) inset, 0 0.75rem 1.25rem rgba(255, 255, 255, 0.4) inset,
    0 -0.5rem 0.25rem 0 rgb(236, 220, 0) inset;
}

.mintButton::before {
  content: '';
  display: block;
  height: 0.125rem;
  position: absolute;
  top: 0.25rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 3rem);
  background: #fff;
  border-radius: 100%;
  opacity: 0.7;
  background-image: linear-gradient(-270deg, rgba(255, 255, 255, 0) 0%, #ffffff 20%, #ffffff 80%, rgba(255, 255, 255, 0) 100%);
}

.grayScale {
  -webkit-filter: brightness(10%) grayscale(100%);
  -moz-filter: brightness(10%) grayscale(100%);
  -o-filter: brightness(10%) grayscale(100%);
  -ms-filter: brightness(10%) grayscale(100%);
  filter: brightness(10%) grayscale(100%);
  opacity: 0.1;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .rightArrow {
    right: 20%;
  }
  .leftArrow {
    left: 20%;
  }
}
