.connectWalletButton {
  left: 50%;
  transform: translate(-50%, 0);
  background-image: linear-gradient(to bottom, #fffc5d 0%, #ffee00 49%, #f8f000 100%);
  box-shadow: 0 0.25rem 1rem 0 rgba(160, 160, 160, 0.5), 0 0 0.25rem rgb(209, 212, 0) inset, 0 0.75rem 1.25rem rgba(255, 255, 255, 0.4) inset,
    0 -0.5rem 0.25rem 0 rgb(236, 220, 0) inset;
  color: #0dbd00;
  font-family: 'Frankfurter Std';
}

.enterQueueRainbow {
  left: 50%;
  position: absolute;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg) translate(50%, 0);
}