/* mobile */
.enterQueueContainer {
  background-image: linear-gradient(to bottom, #fffd93 0%, #ffee00 49%, #eae200 100%);
  box-shadow: 0 0.25rem 1rem 0 rgba(160, 160, 160, 0.5), 0 0 0.25rem rgb(209, 212, 0) inset, 0 0.75rem 1.25rem rgba(255, 255, 255, 0.4) inset,
    0 -0.5rem 0.25rem 0 rgb(236, 220, 0) inset;
  transform-style: preserve-3d;
}

.enterQueueRainbow {
  top: -1.5rem;
  left: 50%;
  transform: translate(-50%, 0) translateZ(-10px);
}

.enterQueueButton {
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  bottom: 0;
  margin-bottom: -10px;
  font-size: 1.25rem;
  font-weight: 300;
  font-family: 'Frankfurter Std';
  padding: 6px 18px 6px 18px;
  border-radius: 12px;
}

/* tablet */
@media only screen and (min-width: 768px) {
  .enterQueueContainer {
    background-image: linear-gradient(-180deg, #f2ff00 0%, #fffb00 85%);
    box-shadow: 0 0.25rem 1rem 0 rgba(160, 160, 160, 0.5), 0 0 0.25rem rgb(209, 212, 0) inset, 0 0.75rem 1.25rem rgba(255, 255, 255, 0.4) inset,
      0 -0.5rem 0.25rem 0 rgb(236, 220, 0) inset;
  }
}

/* desktop */
@media only screen and (min-width: 1024px) {
  .enterQueueContainer {
    background-image: linear-gradient(to bottom, #fffc5d 0%, #ffee00 49%, #f8f000 100%);
    box-shadow: 0 0.25rem 1rem 0 rgba(160, 160, 160, 0.5), 0 0 0.25rem rgb(209, 212, 0) inset, 0 0.75rem 1.25rem rgba(255, 255, 255, 0.4) inset,
      0 -0.5rem 0.25rem 0 rgb(236, 220, 0) inset;
    width: 200px;
    height: 250px;
  }
}
